import React, { useCallback, useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { AppDispatch, RootState } from '@redux/store';
import styles from './Applications.module.scss';
import { AppType } from '@shared/types';
import { TaskStatus } from '@shared/enums';
import { resetSomeFiltersFields, setFilterModalState } from '@redux/slices/filterSlices';

// import { Card } from '@components/UI/Card';
import NewCard from '@components/UI/NewCard/NewCard';
import Preloader from '@components/UI/Preloader/Preloader';
import { ModalAppFilter } from '@components/Modal/ModalAppFilter/ModalAppFilter';
import FilterInput from '@components/UI/FilterInput';
import ExtendButton from '@components/UI/ExtendButton';
import { fetchAPISimple } from 'api';
import { useDebounce } from '@shared/hooks/useDebounce';
import { VariableSizeList, FixedSizeList as List, FixedSizeList, ListOnScrollProps } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from 'react-window-infinite-loader';

// const NewCard = React.lazy(() => import('@components/UI/NewCard/NewCard'));
// const Card = React.lazy(() => import('@components/UI/Card'));

// export const Applications = () => {
//   const { application } = useParams();
//   const {
//     isOpen,
//     isActive,
//     statusFilter,
//     isWithoutExecutor,
//     servFilter,
//     initiatorFilter,
//     coordinatorFilter,
//     categoryFilter,
//     userFilter,
//   } = useSelector((state: RootState) => state.filter);
//   const { isAppsRefreshing } = useSelector((state: RootState) => state.apps);
//   const [appDesc, setAppDesc] = useState<string>('');
//   const [currTasks, setCurrTasks] = useState<AppType[]>([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [currPage, setCurrPage] = useState(1);
//   const [isEnd, setIsEnd] = useState(false);
//   const [itemStatusMap, setItemStatusMap] = useState({});
//   const [itemCount, setItemCount] = useState(1000);
//   const dispatch = useDispatch<AppDispatch>();
//   // const debouncedValue = useDebounce<string>(appDesc, 500);

//   // сборщик тела запроса из фильтров
//   const appBodyReq = useCallback(() => {
//     const reqBody = {};
//     servFilter.UID && Object.assign(reqBody, { ApplicationServise: servFilter.UID });
//     userFilter.UID && Object.assign(reqBody, { ApplicationTo: userFilter.UID });
//     initiatorFilter.UID && Object.assign(reqBody, { ApplicationFrom: initiatorFilter.UID });
//     coordinatorFilter.UID && Object.assign(reqBody, { ApplicationCoordinator: coordinatorFilter.UID });
//     categoryFilter.UID && Object.assign(reqBody, { category: categoryFilter.UID });
//     statusFilter.length && Object.assign(reqBody, { statusFilter: statusFilter });
//     isWithoutExecutor && Object.assign(reqBody, { ApplicationTo: '' });
//     appDesc && Object.assign(reqBody, { search: appDesc });
//     // !!debouncedValue && Object.assign(reqBody, { search: debouncedValue });
//     return reqBody;
//   }, [
//     categoryFilter.UID,
//     coordinatorFilter.UID,
//     initiatorFilter.UID,
//     servFilter.UID,
//     userFilter.UID,
//     statusFilter,
//     // debouncedValue,
//     appDesc,
//     isWithoutExecutor,
//   ]);

//   const containerRef = useRef(null);
//   const infiniteLoaderRef = useRef<InfiniteLoader>(null);

//   useEffect(() => {
//     setIsLoading(true);
//     setCurrTasks([]);
//     setCurrPage(1);
//     // dispatch(setFilterModalState(false));
//     // fetchAPISimple('Applications', { part: 1, ...appBodyReq() })
//     //   .then((res) => setCurrTasks(res))
//     //   .catch((err) => console.error(err))
//     //   .finally(() => setIsLoading(false));
//     // const updateInterval = localStorage.getItem('updateInterval');
//     // if (!!updateInterval && Number(updateInterval)) {
//     //   const interval = setInterval(() => {
//     //     fetchAPISimple('Applications', { part: 1, ...appBodyReq() })
//     //       .then((res) => setCurrTasks([...currTasks, ...res]))
//     //       .catch((err) => console.error(err));
//     //   }, +updateInterval * 6000);
//     //   return () => clearInterval(interval);
//     // }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [appBodyReq, isAppsRefreshing]);

//   // useEffect(()=>{
//   //   if(currTasks.length === 0 && !isEnd) {
//   //     fetchAPISimple('Applications', { part: currPage, ...appBodyReq() })
//   //     .then((res) => {
//   //       setCurrTasks(res)
//   //       // setCurrPage(currPage + 1)
//   //     })
//   //     .catch((err) => console.error(err))
//   //     .finally(() =>  setIsLoading(false));
//   //   }
//   // },[currTasks, isEnd])

//   const handleReverseArr = () =>
//     setCurrTasks(
//       currTasks
//         .slice(0)
//         .reverse()
//         .map((element) => {
//           return element;
//         })
//     );

//   const handleOnClickByFilters = (v: 'free' | 'Appointed' | 'InProgs') => {
//     setIsLoading(true);
//     setIsEnd(false);
//     switch (v) {
//       case 'free':
//         dispatch(resetSomeFiltersFields({ isWithoutExecutor: !isWithoutExecutor }));
//         return;
//       case 'Appointed':
//         dispatch(resetSomeFiltersFields({ statusFilter: [TaskStatus['Appointed']] }));
//         return;
//       default:
//         dispatch(resetSomeFiltersFields({ statusFilter: [TaskStatus['InProgs']] }));
//         return;
//     }
//   };

//   const handleChangeSearchInput = (v: string) => {
//     setIsEnd(false);
//     setCurrTasks([]);
//     setCurrPage(1);
//     setAppDesc(v);
//   }

//   // useEffect(() => {
//   //   // Проверяем, если текущий текст поиска не пустой, и список задач пуст, то устанавливаем isEnd в true
//   //   if (appDesc && currTasks.length === 0) {
//   //     setIsEnd(true);
//   //   }
//   // }, [appDesc, currTasks]);

//   // useEffect(() => {
//   //   setIsEnd(false); // Сбрасываем isEnd при изменении поисковой строки
//   // }, [appDesc]);

//   const fetchMoreTasks = () => {
//     if (isEnd) return;
//     const nextPage = currPage + 1; // Сохранить значение следующей страницы
//     fetchAPISimple('Applications', { part: currPage, ...appBodyReq() }) // Используйте значение nextPage для запроса
//       .then((res) => {
//         if (res.length === 0 && currTasks.length === 0) {
//           setIsEnd(true);
//           if (infiniteLoaderRef.current) {
//             infiniteLoaderRef.current.resetloadMoreItemsCache(true);
//           }
//         } else {
//           setCurrPage(nextPage); // Установить следующую страницу
//           setCurrTasks((prevTasks) => [...prevTasks, ...res]);
//         }
//       })
//       .catch((err) => {
//         console.error(err);
//       })
//       .finally(() => {
//         setIsLoading(false);
//       }
//       )
//   };

//   return (
//     <div className={cn(styles.info, isOpen && styles.info_blocked)} id="scrollableDiv">
//       <FilterInput
//         // onChange={setAppDesc}
//         onChange={handleChangeSearchInput}
//       />
//       <div className={styles.heading}>
//         <div className={styles.item} onClick={() => dispatch(setFilterModalState(true))}>
//           <span className={cn(styles.filters, isActive && styles.filters_active)} />
//           <p className={styles.text}>Фильтры</p>
//         </div>
//         <div className={styles.item} onClick={handleReverseArr}>
//           <span className={styles.sorting} />
//           <p className={styles.text}>Сортировка</p>
//         </div>
//       </div>

//       <div className={styles.tagContainer}>
//         {/* <ScrollContainer className={styles.tags} vertical={false}> */}
//         <div className={styles.tags}>
//           <button
//             className={cn(styles.tag, isWithoutExecutor && styles.tag_active)}
//             onClick={() => handleOnClickByFilters('free')}
//           >
//             Свободные
//           </button>
//           <button
//             className={cn(
//               styles.tag,
//               statusFilter.includes(TaskStatus['Appointed']) && styles.tag_active
//             )}
//             onClick={() => handleOnClickByFilters('Appointed')}
//           >
//             Новая
//           </button>
//           <button
//             className={cn(
//               styles.tag,
//               statusFilter.includes(TaskStatus['InProgs']) && styles.tag_active
//             )}
//             onClick={() => handleOnClickByFilters('InProgs')}
//           >
//             В работе
//           </button>
//         </div>
//         {/* </ScrollContainer> */}
//       </div>

//       <AutoSizer style={{ height: '100%' }}>
//         {({ height, width }: { height: number, width: number }) => (
//           < InfiniteLoader
//             ref={infiniteLoaderRef}
//             // isItemLoaded={index => !!currTasks[index]}
//             isItemLoaded={index => isEnd || index < currTasks.length}
//             // itemCount={isEnd ? currTasks.length : currTasks.length + 1}
//             itemCount={currTasks.length + 1}
//             loadMoreItems={fetchMoreTasks}
//             threshold={2}
//           >
//             {({ onItemsRendered, ref }) => (
//               <div style={{ height: '100%', width: '100%' }}>
//                 <List
//                   className={styles.cards}
//                   height={height} // Установите желаемую высоту списка
//                   // itemCount={isEnd ? currTasks.length : currTasks.length + 1}
//                   itemCount={currTasks.length + 1}
//                   itemSize={130} // Установите высоту каждого элемента
//                   width={width} // Или другая желаемая ширина
//                   onItemsRendered={onItemsRendered}
//                   ref={ref}
//                   style={{ padding: '15px auto' }}
//                 >
//                   {({ index, style }) => {
//                     if (index === currTasks.length && !isEnd) {
//                       if (isLoading) {
//                         return <div style={style}>Loading...</div>;
//                       } else {
//                         return null;
//                       }
//                     }
//                     const task = currTasks[index];
//                     if (!task) return null;
//                     return (
//                       <div style={{ ...style, padding: '15px' }}>
//                         <NewCard
//                           key={currTasks[index].UID}
//                           cardInfo={currTasks[index]}
//                           UID={currTasks[index].UID}
//                           isActive={currTasks[index].UID === application}
//                           onClickHandler={() => { }}
//                         />
//                       </div>
//                     );
//                   }}
//                 </List>
//                 {isEnd && currTasks.length === 0 && (
//                   <div style={{ textAlign: 'center', padding: '10px 0' }}>Данные закончились</div>
//                 )}
//               </div>
//             )}
//           </InfiniteLoader>
//         )}
//       </AutoSizer>

//       {isOpen && <ModalAppFilter />}
//     </div >
//   );
// };

export const Applications = () => {
  const { application } = useParams();
  const {
    isOpen,
    isActive,
    statusFilter,
    isWithoutExecutor,
    servFilter,
    initiatorFilter,
    coordinatorFilter,
    categoryFilter,
    userFilter,
    search
  } = useSelector((state: RootState) => state.filter);
  const filterBody = useSelector((state: RootState) => state.filter);
  const { isAppsRefreshing } = useSelector((state: RootState) => state.apps);
  const [appDesc, setAppDesc] = useState<string>('');
  const [currTasks, setCurrTasks] = useState<AppType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [isEnd, setIsEnd] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const infiniteLoaderRef = useRef<InfiniteLoader>(null);
  const listRef = useRef<HTMLDivElement>(null);


  const appBodyReq = useCallback(() => {
    const reqBody = {
      ...(servFilter.UID && { ApplicationServise: servFilter.UID }),
      ...(userFilter.UID && { ApplicationTo: userFilter.UID }),
      ...(initiatorFilter.UID && { ApplicationFrom: initiatorFilter.UID }),
      ...(coordinatorFilter.UID && { ApplicationCoordinator: coordinatorFilter.UID }),
      ...(categoryFilter.UID && { category: categoryFilter.UID }),
      ...(statusFilter.length && { statusFilter: statusFilter }),
      ...(isWithoutExecutor && { ApplicationTo: '' }),
      ...(appDesc && { search: appDesc }),
    };
    return reqBody;
  }, [servFilter, userFilter, initiatorFilter, coordinatorFilter, categoryFilter, statusFilter, isWithoutExecutor, appDesc]);

  useEffect(() => {
    setIsLoading(true);
    setCurrTasks([]);
    setCurrPage(1);
    fetchTasks(currPage)
  }, [appBodyReq, isAppsRefreshing]);

  const fetchTasks = useCallback(async (page: number) => {
    setIsLoading(true);
    try {
      const res = await fetchAPISimple('Applications', { part: page, ...appBodyReq() });
      setCurrTasks((prevTasks) => [...prevTasks, ...res]);
      if (res.length === 0) {
        setIsEnd(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [appBodyReq]);

  // const fetchTasks = async (page: number) => {
  //   if (page === 1) console.log(appBodyReq())
  //   setIsLoading(true);
  //   try {
  //     const res = await fetchAPISimple('Applications', { part: page, appBodyReq() });
  //     setCurrTasks((prevTasks) => [...prevTasks, ...res]);
  //     if (res.length === 0 && currTasks.length === 0) {
  //       setIsEnd(true);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleReverseArr = useCallback(() => {
    setCurrTasks(currTasks.slice().reverse());
  }, [currTasks]);

  const handleOnClickByFilters = useCallback((v: 'free' | 'Appointed' | 'InProgs') => {
    setIsLoading(true);
    setIsEnd(false);
    setCurrPage(1);
    switch (v) {
      case 'free':
        dispatch(resetSomeFiltersFields({ isWithoutExecutor: !isWithoutExecutor }));
        break;
      case 'Appointed':
        dispatch(resetSomeFiltersFields({ statusFilter: [TaskStatus['Appointed']] }));
        break;
      default:
        dispatch(resetSomeFiltersFields({ statusFilter: [TaskStatus['InProgs']] }));
        break;
    }
  }, [dispatch, isWithoutExecutor]);

  const handleChangeSearchInput = useCallback((v: string) => {
    setIsEnd(false);
    setCurrTasks([]);
    setCurrPage(1);
    setAppDesc(v);
  }, []);

  const fetchMoreTasks = useCallback(() => {
    if (!isEnd) {
      const nextPage = currPage + 1;
      fetchTasks(nextPage);
      setCurrPage(nextPage);
    }
  }, [isEnd, currPage, fetchTasks]);

  // const handleScroll = ({ scrollDirection, scrollOffset }: ListOnScrollProps) => {
  //   if (scrollDirection === 'forward' && scrollOffset > 0.9 && !isEnd && !isLoading) {
  //     fetchMoreTasks();
  //   }
  // };

  const handleScroll = ({ scrollOffset, scrollDirection }: ListOnScrollProps) => {
    const lastVisibleIndex = Math.floor(scrollOffset / 130); // Предполагая, что высота элемента списка составляет 130 пикселей
    const totalItems = currTasks.length;
    const isNearEnd = totalItems - lastVisibleIndex <= 5; // Определяем, что осталось меньше 5 элементов до конца списка

    // Проверяем, достигли ли мы конца списка и не загружаем ли уже данные
    if (scrollDirection === 'forward' && isNearEnd && !isLoading && !isEnd) {
      fetchMoreTasks();
    }
  };

  // useEffect(() => {
  //   if(statusFilter.length === 0) return
  //   fetchTasks(1);
  //   // console.log(statusFilter.length)
  // }, [statusFilter]);

  // if (statusFilter.length === 0 && !isWithoutExecutor) return null

  return (
    <div className={cn(styles.info, isOpen && styles.info_blocked)}>
      <FilterInput onChange={handleChangeSearchInput}/>
      <div className={styles.heading}>
        <div className={styles.item} onClick={() => dispatch(setFilterModalState(true))}>
          <span className={cn(styles.filters, isActive && styles.filters_active)} />
          <p className={styles.text}>Фильтры</p>
        </div>
        <div className={styles.item} onClick={handleReverseArr}>
          <span className={styles.sorting} />
          <p className={styles.text}>Сортировка</p>
        </div>
      </div>

      <div className={styles.tagContainer}>
        <div className={styles.tags}>
          <button
            className={cn(styles.tag, isWithoutExecutor && styles.tag_active)}
            onClick={() => handleOnClickByFilters('free')}
          >
            Свободные
          </button>
          <button
            className={cn(styles.tag, statusFilter.includes(TaskStatus['Appointed']) && styles.tag_active)}
            onClick={() => handleOnClickByFilters('Appointed')}
          >
            Новая
          </button>
          <button
            className={cn(styles.tag, statusFilter.includes(TaskStatus['InProgs']) && styles.tag_active)}
            onClick={() => handleOnClickByFilters('InProgs')}
          >
            В работе
          </button>
        </div>
      </div>

      <AutoSizer style={{ height: '100%' }}>
        {({ height, width }: { height: number, width: number }) => (
          // <InfiniteLoader
          //   ref={infiniteLoaderRef}
          //   isItemLoaded={index => isEnd || index < currTasks.length}
          //   itemCount={currTasks.length + 1}
          //   // loadMoreItems={fetchMoreTasks}
          //   // threshold={1}
          //   loadMoreItems={startIndex => {
          //     // Проверяем, завершилась ли первая загрузка данных
          //     if (initialLoadComplete) {
          //       // Проверяем, есть ли еще элементы для загрузки
          //       if (startIndex >= currTasks.length) {
          //         fetchMoreTasks();
          //       }
          //     }
          //   }}
          // >
          //   {({ onItemsRendered, ref }) => (
          <div style={{ height: '100%', width: '100%' }}>
            <List
              className={styles.cards}
              height={height}
              itemCount={currTasks.length + 1}
              itemSize={130}
              width={width}
              // onItemsRendered={onItemsRendered}
              // ref={ref}
              style={{ padding: '15px auto' }}
              onScroll={handleScroll}
            >
              {({ index, style }) => {
                if (index === currTasks.length && !isEnd) {
                  if (isLoading) {
                    return <div style={style}>Loading...</div>;
                  } else {
                    return null;
                    // return (
                    //   <div style={style}>
                    //     {/* Здесь может быть ваш дозагрузчик */}
                    //     <button onClick={fetchMoreTasks}>Load more</button>
                    //   </div>
                    // );
                  }
                }
                const task = currTasks[index];
                if (!task) return null;
                return (
                  <div style={{ ...style, padding: '15px' }}>
                    <NewCard
                      key={task.UID}
                      cardInfo={task}
                      UID={task.UID}
                      isActive={task.UID === application}
                      onClickHandler={() => { }}
                    />
                  </div>
                );
              }}
            </List>
            {isEnd && currTasks.length === 0 && (
              <div style={{ textAlign: 'center', padding: '10px 0' }}>Данные закончились</div>
            )}
          </div>
          //   )}
          // </InfiniteLoader>
        )}
      </AutoSizer>

      {isOpen && <ModalAppFilter />}
    </div>
  );
};


export const ApplicationsModal = ({
  isAppListOpen,
  setAppListOpen,
}: {
  isAppListOpen: boolean;
  setAppListOpen: (v: boolean) => void;
}) => {
  return (
    <div className={styles.appContainer}>
      <ExtendButton
        onClick={() => setAppListOpen(!isAppListOpen)}
        direction={isAppListOpen ? 'left' : 'right'}
        backColor="#fff"
        className={cn(styles.extendButton, isAppListOpen && styles.extendButton_active)}
      />
      <div className={cn(styles.applications, isAppListOpen && styles.applications_open)}>
        <Applications />
      </div>
    </div>
  );
};
