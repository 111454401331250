import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFilter, ServiceType } from '@shared/types';

interface filterState extends IFilter {
  isOpen: boolean;
  isActive: boolean;
  search: string
}

const initialState: filterState = {
  isOpen: false,
  isActive: false,
  servFilter: { Name: '', UID: '' },
  categoryFilter: { Name: '', UID: '' },
  statusFilter: [],
  dateRangeFilter: [0, 0],
  userFilter: { Name: '', UID: '' },
  initiatorFilter: { Name: '', UID: '' },
  coordinatorFilter: { Name: '', UID: '' },
  isWithoutExecutor: false,
  search: ''
};

const handleIsActive = (state: filterState): boolean =>
  !!state.categoryFilter.UID ||
  !!state.coordinatorFilter.UID ||
  !!state.dateRangeFilter[0] ||
  !!state.dateRangeFilter[1] ||
  !!state.initiatorFilter.UID ||
  !!state.servFilter.UID ||
  !!state.statusFilter.length ||
  !!state.userFilter.UID;

const FilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setSavedFilterState(state) {
      const { appFilters } = JSON.parse(localStorage.getItem('appFilters') || '{"appFilters": ""}');
      return {
        ...state,
        ...(appFilters || initialState),
        isActive: appFilters && handleIsActive(appFilters),
      };
    },
    setFilterModalState(state, action: PayloadAction<boolean>) {
      return { ...state, isOpen: action.payload };
    },
    setFilterService(state, action: PayloadAction<ServiceType>) {
      const newState = { ...state, servFilter: action.payload };
      localStorage.setItem('appFilters', JSON.stringify({ appFilters: newState }));
      return { ...newState, isActive: handleIsActive(newState) };
    },
    setFilterFields(state, action: PayloadAction<IFilter>) {
      const newState = { ...state, ...action.payload };
      localStorage.setItem('appFilters', JSON.stringify({ appFilters: newState }));
      return { ...state, ...action.payload, isActive: true };
    },
    setFilterSearch(state, action: PayloadAction<string>) {
      return { ...state, search: action.payload };
    },
    resetFiltersFields() {
      localStorage.setItem('appFilters', JSON.stringify({ appFilters: initialState }));
      return { ...initialState };
    },
    resetFilerStatus(state) {
      const newState = { ...state, statusFilter: [] };
      localStorage.setItem('appFilters', JSON.stringify({ appFilters: newState }));
      return { ...newState, isActive: handleIsActive(newState) };
    },
    resetSomeFiltersFields(state, { payload }: PayloadAction<Partial<IFilter>>) {
      const { statusFilter, ...rest } = payload;
      let newState = { ...initialState, ...rest };
      if (statusFilter) {
        const newArr = statusFilter.filter((item) => !state.statusFilter.includes(item) && item);
        newState = { ...newState, statusFilter: newArr };
      }
      localStorage.setItem('appFilters', JSON.stringify({ appFilters: newState }));
      return { ...newState, isActive: handleIsActive(newState) };
    },
  },
});

export const {
  setSavedFilterState,
  setFilterModalState,
  setFilterService,
  setFilterFields,
  setFilterSearch,
  resetFiltersFields,
  resetFilerStatus,
  resetSomeFiltersFields,
} = FilterSlice.actions;

export default FilterSlice.reducer;
