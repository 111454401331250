import React, { useEffect, useState } from 'react';

import styles from '../MoreSettings.module.scss';
import Switcher from '@components/UI/Switcher';
import { requestForToken } from '../../../firebase';

const NotificationComponent = () => {
  const [isGranted, setIsGranted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsGranted(Notification.permission === 'granted');
    setIsDisabled(Notification.permission === 'denied');
  }, []);

  const handleRequest = () => {
    if (Notification.permission === 'granted') return;
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        requestForToken()
          .then(() => setIsGranted(true))
          .catch(() => setIsGranted(false));
      } else {
        setIsGranted(false);
      }
    });
  };

  const handleSwitchChange = () => {
    if (isGranted) {
      setIsGranted(false); // Отмена разрешения на уведомления
      // revokeToken()
      // .then(() => console.log("Токен отозван"))
      // .catch((error) => console.error("Ошибка при отзыве токена:", error));
    } else {
      handleRequest(); // Запрос разрешения на уведомления
    }
  };

  return (
    <div className={styles.container}>
      {isDisabled ? (
        <>
          <p className={styles.text}>Уведомления заблокированы</p>
          <Switcher checked={isGranted} onChange={handleSwitchChange} disabled={isDisabled} />
        </>
      ) : (
        <>
          <p className={styles.text}>Включить уведомления</p>
          <Switcher
            checked={isGranted}
            onChange={handleSwitchChange}
            disabled={isDisabled || isGranted}
          />
        </>
      )}
    </div>
  );
};

export default NotificationComponent;
