// import React, { useEffect, useState } from 'react';

// import styles from './NotificationBar.module.scss';
// import { AppType } from '@shared/types';

// import { NotificationCard } from '@components/UI/Card';
// import Preloader from '@components/UI/Preloader/Preloader';
// import { SimpleBigButton } from '@components/UI/Button';
// import { fetchDataPostSimple } from 'api';

// const NotificationBar = () => {
//   const [currTasks, setCurrTasks] = useState<AppType[]>([]);
//   const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     fetchDataPostSimple('newmessages')
//       .then((res) => setCurrTasks(res))
//       .catch((err) => console.error(err))
//       .finally(() => setIsLoading(false));
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const handleReadAllNotifications = () => {
//     setIsLoading(true);
//     fetchDataPostSimple('SelectAll')
//       .then(() => setCurrTasks([]))
//       .catch((err) => console.error(err))
//       .finally(() => setIsLoading(false));
//   };

//   return (
//     <div className={styles.info}>
//       {currTasks.length !== 0 && (
//         <div className={styles.heading}>
//           <SimpleBigButton
//             text={'Отметить все как прочитанные?'}
//             disabled={false}
//             handleClick={handleReadAllNotifications}
//           />
//         </div>
//       )}

//       <div className={styles.cards}>
//         {currTasks.length === 0 ? (
//           <div className={styles.heading}>
//             {isLoading ? <Preloader /> : <p className={styles.text}>Уведомлений нет</p>}
//           </div>
//         ) : (
//           currTasks.map((item) => (
//             <NotificationCard
//               key={item.UID}
//               cardInfo={item}
//               UID={item.UID}
//               notificationText={'Новый комментарий'}
//               onClickHandler={() => {}}
//             />
//           ))
//         )}
//       </div>

//       {/* {filterState.isOpen && <ModalAppFilter />} */}
//     </div>
//   );
// };

// export default NotificationBar;

import React, { useState, useEffect } from 'react';
import { AppType } from '@shared/types';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from 'react-window-infinite-loader';
import { fetchDataPostSimple } from 'api';
import { NotificationCard } from '@components/UI/Card';
import Preloader from '@components/UI/Preloader/Preloader';
import { SimpleBigButton } from '@components/UI/Button';

import styles from './NotificationBar.module.scss';


const NotificationBar = () => {
  const [currTasks, setCurrTasks] = useState<AppType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);

  const fetchMoreTasks = () => {
    // Загрузка данных при прокрутке списка
    // Реализуйте логику для загрузки следующей страницы данных
  };

  useEffect(() => {
    setIsLoading(true);
    fetchDataPostSimple('newmessages')
      .then((res) => {
        setCurrTasks(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, []);

  const handleReadAllNotifications = () => {
    setIsLoading(true);
    fetchDataPostSimple('SelectAll')
      .then(() => setCurrTasks([]))
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className={styles.info}>
      <div className={styles.heading}>
        {currTasks.length !== 0 && (
          <div className={styles.heading}>
            <SimpleBigButton
              text={'Отметить все как прочитанные?'}
              disabled={false}
              handleClick={handleReadAllNotifications}
            />
          </div>
        )}
      </div>
      <AutoSizer style={{ maxHeight: '100%', height: '100%' }}>
        {({ height, width }: { height: number, width: number }) => (
          <InfiniteLoader
            isItemLoaded={(index) => index < currTasks.length}
            itemCount={isEnd ? currTasks.length : currTasks.length + 1}
            loadMoreItems={fetchMoreTasks}
          >
            {({ onItemsRendered, ref }) => (
              <div style={{ height: '100%', width: '100%' }}>
                <List
                  className={styles.cards}
                  height={height}
                  width={width}
                  itemCount={isEnd ? currTasks.length : currTasks.length + 1}
                  itemSize={170}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  style={{ overflowY: 'scroll' }}
                >
                  {({ index, style }) => {
                    if (index === currTasks.length) {
                      if (isLoading) {
                        return <div style={style}><Preloader /></div>;
                      } else {
                        return null;
                      }
                    }
                    const item = currTasks[index];
                    return (
                      <div style={{ ...style, padding: '15px' }}>
                        <NotificationCard
                          key={item.UID}
                          cardInfo={item}
                          UID={item.UID}
                          notificationText={'Новый комментарий'}
                          onClickHandler={() => { }}
                        />
                      </div>
                    );
                  }}
                </List>
              </div>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </div>
  );
};

export default NotificationBar;

